@charset "UTF-8";
/*
  SASS variables are information about icon's compiled state, stored under its original file name

  .icon-home
    width: $icon-home-width

  The large array-like variables contain all information about a single icon
  $icon-home: x y offset_x offset_y width height total_width total_height image_path

  At the bottom of this section, we provide information about the spritesheet itself
  $spritesheet: width height image $spritesheet-sprites
  */
/*
  The provided mixins are intended to be used with the array-like variables

  .icon-home
    @include sprite-width($icon-home)

  .icon-email
    @include sprite($icon-email)
  */
/*
  The `sprites` mixin generates identical output to the CSS template
    but can be overridden inside of SASS

  @include sprites($spritesheet-sprites)
  */
.mb1 {
  margin-bottom: 1px !important; }

.mt1 {
  margin-top: 1px !important; }

.mr1 {
  margin-right: 1px !important; }

.mb2 {
  margin-bottom: 2px !important; }

.mt2 {
  margin-top: 2px !important; }

.mr2 {
  margin-right: 2px !important; }

.mb3 {
  margin-bottom: 3px !important; }

.mt3 {
  margin-top: 3px !important; }

.mr3 {
  margin-right: 3px !important; }

.mb4 {
  margin-bottom: 4px !important; }

.mt4 {
  margin-top: 4px !important; }

.mr4 {
  margin-right: 4px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mt5 {
  margin-top: 5px !important; }

.mr5 {
  margin-right: 5px !important; }

.mb6 {
  margin-bottom: 6px !important; }

.mt6 {
  margin-top: 6px !important; }

.mr6 {
  margin-right: 6px !important; }

.mb7 {
  margin-bottom: 7px !important; }

.mt7 {
  margin-top: 7px !important; }

.mr7 {
  margin-right: 7px !important; }

.mb8 {
  margin-bottom: 8px !important; }

.mt8 {
  margin-top: 8px !important; }

.mr8 {
  margin-right: 8px !important; }

.mb9 {
  margin-bottom: 9px !important; }

.mt9 {
  margin-top: 9px !important; }

.mr9 {
  margin-right: 9px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mt10 {
  margin-top: 10px !important; }

.mr10 {
  margin-right: 10px !important; }

.mb11 {
  margin-bottom: 11px !important; }

.mt11 {
  margin-top: 11px !important; }

.mr11 {
  margin-right: 11px !important; }

.mb12 {
  margin-bottom: 12px !important; }

.mt12 {
  margin-top: 12px !important; }

.mr12 {
  margin-right: 12px !important; }

.mb13 {
  margin-bottom: 13px !important; }

.mt13 {
  margin-top: 13px !important; }

.mr13 {
  margin-right: 13px !important; }

.mb19 {
  margin-bottom: 19px !important; }

.mt19 {
  margin-top: 19px !important; }

.mr19 {
  margin-right: 19px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mt20 {
  margin-top: 20px !important; }

.mr20 {
  margin-right: 20px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.mt25 {
  margin-top: 25px !important; }

.mr25 {
  margin-right: 25px !important; }

.mb26 {
  margin-bottom: 26px !important; }

.mt26 {
  margin-top: 26px !important; }

.mr26 {
  margin-right: 26px !important; }

.mb34 {
  margin-bottom: 34px !important; }

.mt34 {
  margin-top: 34px !important; }

.mr34 {
  margin-right: 34px !important; }

.mb37 {
  margin-bottom: 37px !important; }

.mt37 {
  margin-top: 37px !important; }

.mr37 {
  margin-right: 37px !important; }

.mb38 {
  margin-bottom: 38px !important; }

.mt38 {
  margin-top: 38px !important; }

.mr38 {
  margin-right: 38px !important; }

.mb39 {
  margin-bottom: 39px !important; }

.mt39 {
  margin-top: 39px !important; }

.mr39 {
  margin-right: 39px !important; }

.mb51 {
  margin-bottom: 51px !important; }

.mt51 {
  margin-top: 51px !important; }

.mr51 {
  margin-right: 51px !important; }

.mb52 {
  margin-bottom: 52px !important; }

.mt52 {
  margin-top: 52px !important; }

.mr52 {
  margin-right: 52px !important; }

.mb63 {
  margin-bottom: 63px !important; }

.mt63 {
  margin-top: 63px !important; }

.mr63 {
  margin-right: 63px !important; }

.mb64 {
  margin-bottom: 64px !important; }

.mt64 {
  margin-top: 64px !important; }

.mr64 {
  margin-right: 64px !important; }

.mb65 {
  margin-bottom: 65px !important; }

.mt65 {
  margin-top: 65px !important; }

.mr65 {
  margin-right: 65px !important; }

.mb66 {
  margin-bottom: 66px !important; }

.mt66 {
  margin-top: 66px !important; }

.mr66 {
  margin-right: 66px !important; }

.mb77 {
  margin-bottom: 77px !important; }

.mt77 {
  margin-top: 77px !important; }

.mr77 {
  margin-right: 77px !important; }

.mb78 {
  margin-bottom: 78px !important; }

.mt78 {
  margin-top: 78px !important; }

.mr78 {
  margin-right: 78px !important; }

.mb79 {
  margin-bottom: 79px !important; }

.mt79 {
  margin-top: 79px !important; }

.mr79 {
  margin-right: 79px !important; }

.mb90 {
  margin-bottom: 90px !important; }

.mt90 {
  margin-top: 90px !important; }

.mr90 {
  margin-right: 90px !important; }

.mb91 {
  margin-bottom: 91px !important; }

.mt91 {
  margin-top: 91px !important; }

.mr91 {
  margin-right: 91px !important; }

.mb107 {
  margin-bottom: 107px !important; }

.mt107 {
  margin-top: 107px !important; }

.mr107 {
  margin-right: 107px !important; }

.mb108 {
  margin-bottom: 108px !important; }

.mt108 {
  margin-top: 108px !important; }

.mr108 {
  margin-right: 108px !important; }

.mb109 {
  margin-bottom: 109px !important; }

.mt109 {
  margin-top: 109px !important; }

.mr109 {
  margin-right: 109px !important; }

.mb110 {
  margin-bottom: 110px !important; }

.mt110 {
  margin-top: 110px !important; }

.mr110 {
  margin-right: 110px !important; }

.mb116 {
  margin-bottom: 116px !important; }

.mt116 {
  margin-top: 116px !important; }

.mr116 {
  margin-right: 116px !important; }

.mb117 {
  margin-bottom: 117px !important; }

.mt117 {
  margin-top: 117px !important; }

.mr117 {
  margin-right: 117px !important; }

.col-225, .col-300, .col-450 {
  float: left; }

.col-225 {
  width: 225px; }

.col-300 {
  width: 300px; }

.col-450 {
  width: 450px; }

.ic-ar-link-basic-r-s, .ic-ar-link-basic-r-m, .ic-headline-orange, .ic-ar-shougakusei-r-m, .ic-ar-shougakusei-r-s, .ic-ar-shougakusei-d-m, .ic-accent, .ic-all-s, .ic-all-m, .ic-nin-s, .ic-nin-m, .ic-nyu-s, .ic-nyu-m, .ic-you-s, .ic-you-m, .ic-sho-s, .ic-sho-m, .ic-chu-s, .ic-chu-m, .ic-kou-s, .ic-kou-m, .ic-sya-s, .ic-sya-m, .ic-sen-s, .ic-sen-m, .tab-service, .tab-member, .tab-search, .tab-service2, .tab-search2, .menu--service .tab-service, .btn-nin, .btn-nyu, .btn-you, .btn-sho, .btn-chu, .btn-kou, .btn-gaku, .btn-sen, .txt-ei, .txt-ga, .txt-hou, .txt-jyu, .txt-kai, .txt-shu, .txt-pet, .txt-ryu, .txt-sei, .btn-find-by-area, .btn-slide-open, .btn-slide-close, .ic-koukoukaiin, .ic-everes, .ic-info_site, .ic-challengenet, .ic-challengeweb, .ic-shimajiro_op, .ic-shimakura, .ic-sho_op, .ic-plusi {
  position: relative;
  display: inline-block;
  vertical-align: middle; }

.ic-ar-link-basic-r-s {
  background-image: url(__spriteSheet.png);
  background-position: -243px -378px;
  width: 15px;
  height: 15px; }

.ic-ar-link-basic-r-m {
  background-image: url(__spriteSheet.png);
  background-position: -448px -169px;
  width: 17px;
  height: 17px; }

.ic-headline-orange {
  background-image: url(__spriteSheet.png);
  background-position: -449px -106px;
  width: 17px;
  height: 17px; }

.ic-ar-shougakusei-r-m {
  background-image: url(__spriteSheet.png);
  background-position: -450px -64px;
  width: 18px;
  height: 17px; }

.ic-ar-shougakusei-r-s {
  background-image: url(__spriteSheet.png);
  background-position: -224px -378px;
  width: 15px;
  height: 15px; }

.ic-ar-shougakusei-d-m {
  background-image: url(__spriteSheet.png);
  background-position: -449px -127px;
  width: 17px;
  height: 17px; }

.ic-accent {
  background-image: url(__spriteSheet.png);
  background-position: -450px -85px;
  width: 18px;
  height: 17px; }

.ic-all-s {
  background-image: url(__spriteSheet.png);
  background-position: -372px -44px;
  width: 78px;
  height: 16px; }

.ic-all-m {
  background-image: url(__spriteSheet.png);
  background-position: -372px -22px;
  width: 99px;
  height: 18px; }

.ic-nin-s {
  background-image: url(__spriteSheet.png);
  background-position: -180px -357px;
  width: 26px;
  height: 16px; }

.ic-nin-m {
  background-image: url(__spriteSheet.png);
  background-position: -260px -148px;
  width: 32px;
  height: 18px; }

.ic-nyu-s {
  background-image: url(__spriteSheet.png);
  background-position: -120px -357px;
  width: 26px;
  height: 16px; }

.ic-nyu-m {
  background-image: url(__spriteSheet.png);
  background-position: -260px -184px;
  width: 32px;
  height: 18px; }

.ic-you-s {
  background-image: url(__spriteSheet.png);
  background-position: -210px -357px;
  width: 26px;
  height: 16px; }

.ic-you-m {
  background-image: url(__spriteSheet.png);
  background-position: -158px -378px;
  width: 18px;
  height: 18px; }

.ic-sho-s {
  background-image: url(__spriteSheet.png);
  background-position: -150px -357px;
  width: 26px;
  height: 16px; }

.ic-sho-m {
  background-image: url(__spriteSheet.png);
  background-position: -180px -378px;
  width: 18px;
  height: 18px; }

.ic-chu-s {
  background-image: url(__spriteSheet.png);
  background-position: -445px -211px;
  width: 26px;
  height: 16px; }

.ic-chu-m {
  background-image: url(__spriteSheet.png);
  background-position: -270px -240px;
  width: 18px;
  height: 18px; }

.ic-kou-s {
  background-image: url(__spriteSheet.png);
  background-position: -445px -190px;
  width: 26px;
  height: 16px; }

.ic-kou-m {
  background-image: url(__spriteSheet.png);
  background-position: -202px -378px;
  width: 18px;
  height: 18px; }

.ic-sya-s {
  background-image: url(__spriteSheet.png);
  background-position: -336px -276px;
  width: 26px;
  height: 16px; }

.ic-sya-m {
  background-image: url(__spriteSheet.png);
  background-position: -122px -378px;
  width: 32px;
  height: 18px; }

.ic-sen-s {
  background-image: url(__spriteSheet.png);
  background-position: -433px -334px;
  width: 32px;
  height: 16px; }

.ic-sen-m {
  background-image: url(__spriteSheet.png);
  background-position: 0px -378px;
  width: 39px;
  height: 18px; }

.tab-service {
  background-image: url(__spriteSheet.png);
  background-position: 0px -148px;
  width: 126px;
  height: 32px; }
  .tab-service:hover {
    background-image: url(__spriteSheet.png);
    background-position: -130px -184px;
    width: 126px;
    height: 32px; }

.tab-member {
  background-image: url(__spriteSheet.png);
  background-position: -153px -38px;
  width: 128px;
  height: 32px; }
  .tab-member:hover {
    background-image: url(__spriteSheet.png);
    background-position: -153px -74px;
    width: 128px;
    height: 32px; }

.tab-search {
  cursor: pointer;
  background-image: url(__spriteSheet.png);
  background-position: -180px -240px;
  width: 86px;
  height: 32px; }
  .tab-search:hover {
    background-image: url(__spriteSheet.png);
    background-position: 0px -240px;
    width: 86px;
    height: 32px; }

.tab-service2 {
  background-image: url(__spriteSheet.png);
  background-position: 0px -45px;
  width: 149px;
  height: 41px; }
  .tab-service2:hover {
    background-image: url(__spriteSheet.png);
    background-position: 0px 0px;
    width: 149px;
    height: 41px; }

.tab-search2 {
  background-image: url(__spriteSheet.png);
  background-position: -297px -199px;
  width: 50px;
  height: 41px; }
  .tab-search2:hover {
    background-image: url(__spriteSheet.png);
    background-position: -297px -154px;
    width: 50px;
    height: 41px; }

.menu--service .tab-service {
  background-image: url(__spriteSheet.png);
  background-position: -130px -148px;
  width: 126px;
  height: 32px; }
  .menu--service .tab-service:hover {
    background-image: url(__spriteSheet.png);
    background-position: 0px -184px;
    width: 126px;
    height: 32px; }

.menu--search .tab-search {
  background-image: url(__spriteSheet.png);
  background-position: -90px -240px;
  width: 86px;
  height: 32px; }
  .menu--search .tab-search:hover {
    background-image: url(__spriteSheet.png);
    background-position: -90px -240px;
    width: 86px;
    height: 32px; }

.btn-nin {
  background-image: url(__spriteSheet.png);
  background-position: -372px -334px;
  width: 57px;
  height: 17px; }
  .btn-nin:hover {
    background-image: url(__spriteSheet.png);
    background-position: -297px -244px;
    width: 57px;
    height: 17px; }

.btn-nyu {
  background-image: url(__spriteSheet.png);
  background-position: -300px -336px;
  width: 56px;
  height: 17px; }
  .btn-nyu:hover {
    background-image: url(__spriteSheet.png);
    background-position: -240px -336px;
    width: 56px;
    height: 17px; }

.btn-you {
  background-image: url(__spriteSheet.png);
  background-position: -60px -357px;
  width: 56px;
  height: 17px; }
  .btn-you:hover {
    background-image: url(__spriteSheet.png);
    background-position: 0px -357px;
    width: 56px;
    height: 17px; }

.btn-sho {
  background-image: url(__spriteSheet.png);
  background-position: -372px -127px;
  width: 73px;
  height: 17px; }
  .btn-sho:hover {
    background-image: url(__spriteSheet.png);
    background-position: -372px -106px;
    width: 73px;
    height: 17px; }

.btn-chu {
  background-image: url(__spriteSheet.png);
  background-position: -372px -148px;
  width: 72px;
  height: 17px; }
  .btn-chu:hover {
    background-image: url(__spriteSheet.png);
    background-position: -372px -169px;
    width: 72px;
    height: 17px; }

.btn-kou {
  background-image: url(__spriteSheet.png);
  background-position: -372px -64px;
  width: 74px;
  height: 17px; }
  .btn-kou:hover {
    background-image: url(__spriteSheet.png);
    background-position: -372px -85px;
    width: 74px;
    height: 17px; }

.btn-gaku {
  background-image: url(__spriteSheet.png);
  background-position: -120px -336px;
  width: 116px;
  height: 17px; }
  .btn-gaku:hover {
    background-image: url(__spriteSheet.png);
    background-position: 0px -336px;
    width: 116px;
    height: 17px; }

.btn-sen {
  background-image: url(__spriteSheet.png);
  background-position: -372px -190px;
  width: 69px;
  height: 17px; }
  .btn-sen:hover {
    background-image: url(__spriteSheet.png);
    background-position: -372px -211px;
    width: 69px;
    height: 17px; }

.txt-ei {
  background-image: url(__spriteSheet.png);
  background-position: -372px -266px;
  width: 74px;
  height: 13px; }
  .txt-ei:hover {
    background-image: url(__spriteSheet.png);
    background-position: -372px -283px;
    width: 74px;
    height: 13px; }

.txt-ga {
  background-image: url(__spriteSheet.png);
  background-position: -372px -300px;
  width: 72px;
  height: 13px; }
  .txt-ga:hover {
    background-image: url(__spriteSheet.png);
    background-position: -372px -317px;
    width: 72px;
    height: 13px; }

.txt-hou {
  background-image: url(__spriteSheet.png);
  background-position: -299px -357px;
  width: 25px;
  height: 13px; }
  .txt-hou:hover {
    background-image: url(__spriteSheet.png);
    background-position: -270px -357px;
    width: 25px;
    height: 13px; }

.txt-jyu {
  background-image: url(__spriteSheet.png);
  background-position: -277px -296px;
  width: 59px;
  height: 13px; }
  .txt-jyu:hover {
    background-image: url(__spriteSheet.png);
    background-position: -228px -110px;
    width: 59px;
    height: 13px; }

.txt-kai {
  background-image: url(__spriteSheet.png);
  background-position: -240px -357px;
  width: 26px;
  height: 13px; }
  .txt-kai:hover {
    background-image: url(__spriteSheet.png);
    background-position: -336px -316px;
    width: 26px;
    height: 13px; }

.txt-shu {
  background-image: url(__spriteSheet.png);
  background-position: -153px -110px;
  width: 71px;
  height: 13px; }
  .txt-shu:hover {
    background-image: url(__spriteSheet.png);
    background-position: -197px -220px;
    width: 71px;
    height: 13px; }

.txt-pet {
  background-image: url(__spriteSheet.png);
  background-position: -251px -128px;
  width: 33px;
  height: 13px; }
  .txt-pet:hover {
    background-image: url(__spriteSheet.png);
    background-position: -268px -316px;
    width: 33px;
    height: 13px; }

.txt-ryu {
  background-image: url(__spriteSheet.png);
  background-position: -372px -232px;
  width: 86px;
  height: 13px; }
  .txt-ryu:hover {
    background-image: url(__spriteSheet.png);
    background-position: -372px -249px;
    width: 86px;
    height: 13px; }

.txt-sei {
  background-image: url(__spriteSheet.png);
  background-position: -305px -316px;
  width: 27px;
  height: 13px; }
  .txt-sei:hover {
    background-image: url(__spriteSheet.png);
    background-position: -340px -296px;
    width: 27px;
    height: 13px; }

.btn-find-by-area {
  background-image: url(__spriteSheet.png);
  background-position: -153px 0px;
  width: 140px;
  height: 34px; }
  .btn-find-by-area:hover {
    background-image: url(__spriteSheet.png);
    background-position: 0px -90px;
    width: 140px;
    height: 34px; }

.btn-slide-open {
  background-image: url(__spriteSheet.png);
  background-position: -297px -78px;
  width: 71px;
  height: 34px; }
  .btn-slide-open:hover {
    background-image: url(__spriteSheet.png);
    background-position: -297px 0px;
    width: 71px;
    height: 36px; }

.btn-slide-close {
  background-image: url(__spriteSheet.png);
  background-position: -297px -40px;
  width: 71px;
  height: 34px; }
  .btn-slide-close:hover {
    background-image: url(__spriteSheet.png);
    background-position: -297px -116px;
    width: 71px;
    height: 34px; }

.ic-koukoukaiin {
  background-image: url(__spriteSheet.png);
  background-position: 0px -276px;
  width: 135px;
  height: 16px; }

.ic-everes {
  background-image: url(__spriteSheet.png);
  background-position: 0px -128px;
  width: 247px;
  height: 16px; }

.ic-info_site {
  background-image: url(__spriteSheet.png);
  background-position: -278px -276px;
  width: 54px;
  height: 16px; }

.ic-challengenet {
  background-image: url(__spriteSheet.png);
  background-position: 0px -220px;
  width: 193px;
  height: 16px; }

.ic-challengeweb {
  background-image: url(__spriteSheet.png);
  background-position: -139px -276px;
  width: 135px;
  height: 16px; }

.ic-shimajiro_op {
  background-image: url(__spriteSheet.png);
  background-position: -134px -316px;
  width: 130px;
  height: 16px; }

.ic-shimakura {
  background-image: url(__spriteSheet.png);
  background-position: 0px -316px;
  width: 130px;
  height: 16px; }

.ic-sho_op {
  background-image: url(__spriteSheet.png);
  background-position: -139px -296px;
  width: 134px;
  height: 16px; }

.ic-plusi {
  background-image: url(__spriteSheet.png);
  background-position: 0px -296px;
  width: 135px;
  height: 16px; }

body {
  font-family: 'MS PGothic', sans-serif;
  margin: 0;
  background-color: #fff; }

img {
  border: none;
  vertical-align: bottom; }

strong {
  font-weight: bold; }

a {
  color: #000;
  text-decoration: none; }
  a:hover {
    color: #666; }

ul {
  margin: 0;
  padding: 0; }

li {
  list-style-type: none; }

h1, h2, h3, h4 {
  margin: 0; }

hr {
  margin: 0;
  border: none;
  border-top: 1px solid #d9d9d9; }

.header-content, .footer-content, .main-content {
  width: 900px;
  margin: 0 auto; }

.row:after, .horizontal-list:after, .anchor-links:after, .proposal-row:after, .member-available-procedure-lists:after, .subscription-procedures:after, .member-faq-list--by-service:after {
  content: " ";
  display: block;
  clear: both; }

.contact-to-way-label, .contact-to-way-label2 {
  text-indent: 120%;
  overflow: hidden;
  white-space: nowrap; }

.psuedo-radius-wrapper {
  position: relative; }

.psuedo-radius-2-lt, .psuedo-radius-2-rt, .psuedo-radius-2-rb, .psuedo-radius-2-lb, .psuedo-radius-2-lt-gray, .psuedo-radius-2-rt-gray, .psuedo-radius-2-rb-gray, .psuedo-radius-2-lb-gray, .psuedo-radius-border-lt, .psuedo-radius-border-rt, .psuedo-radius-border-rb, .psuedo-radius-border-lb, .psuedo-radius-3-rb-bnr, .psuedo-radius-3-lb-bnr {
  position: absolute;
  display: block;
  vertical-align: bottom; }

.psuedo-radius-2-lt, .psuedo-radius-2-lt-gray {
  top: 0;
  left: 0; }

.psuedo-radius-2-rt, .psuedo-radius-2-rt-gray {
  top: 0;
  right: 0; }

.psuedo-radius-2-rb, .psuedo-radius-2-rb-gray, .psuedo-radius-3-rb-bnr {
  bottom: 0;
  right: 0; }

.psuedo-radius-2-lb, .psuedo-radius-2-lb-gray, .psuedo-radius-3-lb-bnr {
  bottom: 0;
  left: 0; }

.psuedo-radius-2-lt {
  background-image: url(__spriteSheet.png);
  background-position: -469px -154px;
  width: 2px;
  height: 2px; }

.psuedo-radius-2-rt {
  background-image: url(__spriteSheet.png);
  background-position: -469px -169px;
  width: 2px;
  height: 2px; }

.psuedo-radius-2-rb {
  background-image: url(__spriteSheet.png);
  background-position: -469px -259px;
  width: 2px;
  height: 2px; }

.psuedo-radius-2-lb {
  background-image: url(__spriteSheet.png);
  background-position: -469px -181px;
  width: 2px;
  height: 2px; }

.psuedo-radius-2-lt-gray {
  background-image: url(__spriteSheet.png);
  background-position: -469px -175px;
  width: 2px;
  height: 2px; }

.psuedo-radius-2-rt-gray {
  background-image: url(__spriteSheet.png);
  background-position: -469px -160px;
  width: 2px;
  height: 2px; }

.psuedo-radius-2-rb-gray {
  background-image: url(__spriteSheet.png);
  background-position: -469px -148px;
  width: 2px;
  height: 2px; }

.psuedo-radius-2-lb-gray {
  background-image: url(__spriteSheet.png);
  background-position: -469px -242px;
  width: 2px;
  height: 2px; }

.psuedo-radius-border-lt {
  background-image: url(__spriteSheet.png);
  background-position: -462px -249px;
  width: 6px;
  height: 6px;
  top: -2px;
  left: -2px; }

.psuedo-radius-border-rt {
  background-image: url(__spriteSheet.png);
  background-position: -450px -283px;
  width: 6px;
  height: 6px;
  top: -2px;
  right: -2px; }

.psuedo-radius-border-rb {
  background-image: url(__spriteSheet.png);
  background-position: -462px -232px;
  width: 6px;
  height: 6px;
  bottom: -2px;
  right: -2px; }

.psuedo-radius-border-lb {
  background-image: url(__spriteSheet.png);
  background-position: -465px -266px;
  width: 6px;
  height: 6px;
  bottom: -2px;
  left: -2px; }

.psuedo-radius-3-rb-bnr {
  background-image: url(__spriteSheet.png);
  background-position: -462px -259px;
  width: 3px;
  height: 3px; }

.psuedo-radius-3-lb-bnr {
  background-image: url(__spriteSheet.png);
  background-position: -462px -242px;
  width: 3px;
  height: 3px; }

.header {
  font-family: 'MS PGothic', sans-serif;
  font-size: 16px;
  padding-top: 26px;
  margin-bottom: 79px; }
  .header img {
    vertical-align: bottom; }

.header-content {
  position: relative; }

.header-copy {
  margin-left: 16px; }

.header-top {
  padding-top: 26px;
  z-index: 100; }

.header-top-tab {
  position: absolute;
  top: -3px;
  left: -5px;
  background: url(bg/header_logo.png) no-repeat left top;
  width: 367px;
  height: 57px;
  padding-left: 31px;
  z-index: 100; }

.header-top-logo {
  display: inline-block; }

.header-top-copy {
  display: inline-block;
  margin-left: 18px; }

.header-menu {
  line-height: 16px;
  position: absolute;
  top: 10px;
  right: 13px; }

.header-menu-open {
  position: relative;
  display: inline-block;
  cursor: pointer;
  vertical-align: bottom; }

.header-menu-service {
  margin-right: 21px; }

.header-menu-link-member-support {
  display: inline-block;
  margin-right: 26px;
  vertical-align: bottom; }

.footer {
  font-family: 'MS PGothic', sans-serif;
  font-size: 16px;
  padding-top: 64px;
  padding-bottom: 65px;
  background: #f2f2f2 url(bg/footer_border.png) repeat-x top left; }
  .footer .note, .footer .note--aster {
    color: #999;
    line-height: 18px;
    margin-top: 16px; }

.footer-hr {
  margin: 0;
  margin-bottom: 26px;
  border: none;
  border-top: 1px solid #d9d9d9; }

.footer-company-links {
  margin: 0;
  margin-bottom: 50px;
  padding-left: 0; }
  .footer-company-links li {
    display: block;
    margin: 0;
    margin-right: 26px; }
    .footer-company-links li.footer-company-links-r {
      float: right !important;
      margin-right: 0;
      margin-top: -1px; }
    .footer-company-links li a {
      display: inline-block; }

.footer-company-links2 {
  margin: 0;
  margin-bottom: 26px;
  padding-left: 0; }
  .footer-company-links2 li {
    display: block;
    margin: 0;
    margin-right: 20px; }
    .footer-company-links2 li.footer-company-links-r {
      float: right !important;
      margin-right: 0; }
    .footer-company-links2 li a {
      display: inline-block; }

.link-list li [class^="ic-"] {
  margin-right: 6px; }

.horizontal-list li {
  float: left; }

.link-blank:hover, .link-basic:hover, .link-notice:hover {
  text-decoration: underline; }

.link-blank:before {
  content: url(ic/blank.png);
  margin-right: 7px; }

.link-basic:before {
  content: url(ic/basic.png);
  margin-right: 7px;
  position: relative;
  top: 3px; }

.link-notice:before {
  content: url(ic/notice.png);
  margin-right: 7px;
  position: relative;
  top: 3px; }

.link-dot:before {
  content: "・";
  margin-right: 7px; }

.note, .note--aster {
  color: #666;
  font-size: 10px;
  letter-spacing: 0.6px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

.note--aster:before {
  content: "※"; }

.headline {
  margin: 10px; }

.headline-link-r {
  display: block;
  float: right;
  margin-top: 3px;
  padding: 2px 0 1px 23px;
  font-size: 13px;
  font-weight: 400; }

.headline--accent {
  background: url("ic/headline_accent.png") no-repeat 0 2px;
  padding-left: 29px; }

.headline--shougakusei {
  background: url("ic/headline_shougakusei.png") no-repeat 0 3px;
  padding-left: 29px; }

.headline--ninshin {
  background: url("ic/headline_ninshin.png") no-repeat 0 3px;
  padding-left: 29px; }

.headline--nyuuji {
  background: url("ic/headline_nyuuji.png") no-repeat 0 3px;
  padding-left: 29px; }

.headline--youji {
  background: url("ic/headline_youji.png") no-repeat 0 3px;
  padding-left: 29px; }

.headline--chuugakusei {
  background: url("ic/headline_chuugakusei.png") no-repeat 0 3px;
  padding-left: 29px; }

.headline--koukousei {
  background: url("ic/headline_koukousei.png") no-repeat 0 3px;
  padding-left: 29px; }

.headline--shakaijin {
  background: url("ic/headline_shakaijin.png") no-repeat 0 3px;
  padding-left: 29px; }

.headline--mokuteki {
  background: url("ic/headline_mokuteki.png") no-repeat 0 3px;
  padding-left: 29px; }

.top-osusume-list {
  position: relative; }
  .top-osusume-list .psuedo-shadow {
    content: ' ';
    width: 902px;
    height: 158px;
    right: -1px;
    top: 0;
    background: url("bg/shadow_h158.png") no-repeat top left;
    position: absolute;
    z-index: -1; }
  .top-osusume-list .entry-image {
    margin-bottom: 0;
    width: 225px;
    height: 156px;
    overflow: hidden; }
    .top-osusume-list .entry-image img {
      width: 100%; }
  .top-osusume-list.col--1 .psuedo-shadow {
    content: ' ';
    width: 902px;
    height: 158px;
    right: -1px;
    top: 0;
    background: url("bg/shadow_h158__col1.png") no-repeat top left;
    position: absolute;
    z-index: -1; }
  .top-osusume-list.col--1 .psuedo-radius-2-rt,
  .top-osusume-list.col--1 .psuedo-radius-2-rb {
    right: 675px; }
  .top-osusume-list.col--2 .psuedo-shadow {
    content: ' ';
    width: 902px;
    height: 158px;
    right: -1px;
    top: 0;
    background: url("bg/shadow_h158__col2.png") no-repeat top left;
    position: absolute;
    z-index: -1; }
  .top-osusume-list.col--2 .psuedo-radius-2-rt,
  .top-osusume-list.col--2 .psuedo-radius-2-rb {
    right: 450px; }
  .top-osusume-list.col--3 .psuedo-shadow {
    content: ' ';
    width: 902px;
    height: 158px;
    right: -1px;
    top: 0;
    background: url("bg/shadow_h158__col3.png") no-repeat top left;
    position: absolute;
    z-index: -1; }
  .top-osusume-list.col--3 .psuedo-radius-2-rt,
  .top-osusume-list.col--3 .psuedo-radius-2-rb {
    right: 225px; }

.top-event-list {
  margin-bottom: 47px; }
  .top-event-list li {
    margin-left: 30px; }
    .top-event-list li:first-child {
      margin-left: 0; }
  .top-event-list .entry-2 {
    width: 280px;
    box-sizing: border-box; }

.top-feature-list {
  position: relative; }
  .top-feature-list .entry {
    width: 300px; }
  .top-feature-list .psuedo-shadow {
    content: ' ';
    width: 902px;
    height: 132px;
    right: -1px;
    top: 0;
    background: url("bg/shadow_h132.png") no-repeat top left;
    position: absolute;
    z-index: -1; }

.top-event-list .entry-2-image .psuedo-shadow {
  content: ' ';
  width: 106px;
  height: 107px;
  right: -1px;
  top: -1px;
  background: url("bg/shadow_h107.png") no-repeat top left;
  position: absolute;
  z-index: -1; }

.top-torikumi-list {
  position: relative;
  margin-bottom: 34px; }
  .top-torikumi-list .psuedo-shadow {
    content: ' ';
    width: 902px;
    height: 132px;
    right: -1px;
    top: 0;
    background: url("bg/shadow_h132.png") no-repeat top left;
    position: absolute;
    z-index: -1; }
  .top-torikumi-list .psuedo-radius-2-lb,
  .top-torikumi-list .psuedo-radius-2-rb {
    top: 128px; }
  .top-torikumi-list.col--1 .psuedo-shadow {
    content: ' ';
    width: 902px;
    height: 132px;
    right: -1px;
    top: 0;
    background: url("bg/shadow_h132__col1.png") no-repeat top left;
    position: absolute;
    z-index: -1; }
  .top-torikumi-list.col--1 .psuedo-radius-2-rt,
  .top-torikumi-list.col--1 .psuedo-radius-2-rb {
    right: 600px; }
  .top-torikumi-list.col--2 .psuedo-shadow {
    content: ' ';
    width: 902px;
    height: 132px;
    right: -1px;
    top: 0;
    background: url("bg/shadow_h132__col2.png") no-repeat top left;
    position: absolute;
    z-index: -1; }
  .top-torikumi-list.col--2 .psuedo-radius-2-rt,
  .top-torikumi-list.col--2 .psuedo-radius-2-rb {
    right: 300px; }
  .top-torikumi-list .entry {
    box-sizing: border-box;
    width: 300px; }
  .top-torikumi-list .entry-image {
    margin-bottom: 14px;
    width: 100%; }
    .top-torikumi-list .entry-image img {
      width: 100%; }

.top-torikumi-list-slim {
  position: relative; }
  .top-torikumi-list-slim .psuedo-shadow {
    content: ' ';
    width: 902px;
    height: 99px;
    right: -1px;
    top: 0;
    background: url("bg/shadow_h99.png") no-repeat top left;
    position: absolute;
    z-index: -1; }
  .top-torikumi-list-slim .psuedo-radius-2-lb,
  .top-torikumi-list-slim .psuedo-radius-2-rb {
    top: 95px; }
  .top-torikumi-list-slim .entry {
    box-sizing: border-box;
    width: 225px; }
    .top-torikumi-list-slim .entry .entry-image {
      margin-bottom: 16px; }
    .top-torikumi-list-slim .entry img {
      width: 100%; }
    .top-torikumi-list-slim .entry .entry-title {
      margin-top: 0;
      margin-left: -1px; }
  .top-torikumi-list-slim.col--1 .psuedo-shadow {
    content: ' ';
    width: 902px;
    height: 99px;
    right: -1px;
    top: 0;
    background: url("bg/shadow_h99__col1.png") no-repeat top left;
    position: absolute;
    z-index: -1; }
  .top-torikumi-list-slim.col--1 .psuedo-radius-2-rt,
  .top-torikumi-list-slim.col--1 .psuedo-radius-2-rb {
    right: 675px; }
  .top-torikumi-list-slim.col--2 .psuedo-shadow {
    content: ' ';
    width: 902px;
    height: 99px;
    right: -1px;
    top: 0;
    background: url("bg/shadow_h99__col2.png") no-repeat top left;
    position: absolute;
    z-index: -1; }
  .top-torikumi-list-slim.col--2 .psuedo-radius-2-rt,
  .top-torikumi-list-slim.col--2 .psuedo-radius-2-rb {
    right: 450px; }
  .top-torikumi-list-slim.col--3 .psuedo-shadow {
    content: ' ';
    width: 902px;
    height: 99px;
    right: -1px;
    top: 0;
    background: url("bg/shadow_h99__col3.png") no-repeat top left;
    position: absolute;
    z-index: -1; }
  .top-torikumi-list-slim.col--3 .psuedo-radius-2-rt,
  .top-torikumi-list-slim.col--3 .psuedo-radius-2-rb {
    right: 225px; }

.top-important-info-list li {
  font-size: 13px;
  letter-spacing: .78px;
  line-height: 26px; }

.anchor-links-container {
  margin-bottom: 35px; }

.anchor-links {
  margin: 0;
  padding: 0;
  margin-bottom: 15px; }
  .anchor-links li {
    display: block;
    float: left;
    list-style-type: none;
    margin-left: 21px;
    padding-left: 24px;
    background: url("ic/ar_basic_d_m.png") no-repeat 0 0; }
    .anchor-links li:first-child {
      margin-left: 0; }
    .anchor-links li a {
      display: inline-block; }

.l-service-seichou-chuugakusei .anchor-links li {
  padding-left: 24px;
  background: url("ic/ar_chuugakusei_d_m.png") no-repeat 0 0; }

.l-service-seichou-koukousei .anchor-links li {
  padding-left: 24px;
  background: url("ic/ar_koukousei_d_m.png") no-repeat 0 0; }

.l-service-seichou-shakaijin .anchor-links li {
  padding-left: 24px;
  background: url("ic/ar_shakaijin_d_m.png") no-repeat 0 0; }

.l-service-seichou-ninshin .anchor-links li {
  padding-left: 24px;
  background: url("ic/ar_ninshin_d_m.png") no-repeat 0 0; }

.l-service-seichou-nyuuji .anchor-links li {
  padding-left: 24px;
  background: url("ic/ar_nyuuji_d_m.png") no-repeat 0 0; }

.l-service-seichou-senior .anchor-links li {
  padding-left: 24px;
  background: url("ic/ar_senior_d_m.png") no-repeat 0 0; }

.l-service-seichou-shougakusei .anchor-links li {
  padding-left: 24px;
  background: url("ic/ar_shougakusei_d_m.png") no-repeat 0 0; }

.l-service-seichou-youji .anchor-links li {
  padding-left: 24px;
  background: url("ic/ar_youji_d_m.png") no-repeat 0 0; }

.l-service-mokuteki-eigo .anchor-links li,
.l-service-mokuteki-kosodate .anchor-links li,
.l-service-mokuteki-seiseki .anchor-links li,
.l-service-mokuteki-kaigai .anchor-links li,
.l-service-mokuteki-juken .anchor-links li,
.l-service-mokuteki-kaigo .anchor-links li,
.l-service-mokuteki-pet .anchor-links li {
  padding-left: 24px;
  background: url("ic/ar_mokuteki_d_m.png") no-repeat 0 0; }

.proposals {
  margin-bottom: 30px; }

.proposal {
  float: left;
  padding-top: 30px;
  margin-bottom: 35px; }
  .proposal:first-child {
    margin-right: 30px; }

.proposal {
  width: 435px; }

.proposal-headline {
  margin: 0;
  padding-bottom: 32px;
  padding-left: 30px;
  background: url(bg/proposal_headline.png) no-repeat left bottom; }

.proposal-shougakusei .proposal-headline {
  background: url(bg/proposal_headline_shougakusei.png) no-repeat left bottom; }

.proposal-ninshin .proposal-headline {
  background: url(bg/proposal_headline_ninshin.png) no-repeat left bottom; }

.proposal-nyuuji .proposal-headline {
  background: url(bg/proposal_headline_nyuuji.png) no-repeat left bottom; }

.proposal-youji .proposal-headline {
  background: url(bg/proposal_headline_youji.png) no-repeat left bottom; }

.proposal-chuugakusei .proposal-headline {
  background: url(bg/proposal_headline_chuugakusei.png) no-repeat left bottom; }

.proposal-koukousei .proposal-headline {
  background: url(bg/proposal_headline_koukousei.png) no-repeat left bottom; }

.proposal-shakaijin .proposal-headline {
  background: url(bg/proposal_headline_shakaijin.png) no-repeat left bottom; }

.proposal-senior .proposal-headline {
  background: url(bg/proposal_headline_senior.png) no-repeat left bottom; }

/** NOTE: eigo_gakushu と同じ色なので流用 */
.proposal-kaigai .proposal-headline,
.proposal-juken .proposal-headline,
.proposal-kosodate .proposal-headline,
.proposal-seiseki .proposal-headline,
.proposal-eigo .proposal-headline,
.proposal-kaigo .proposal-headline,
.proposal-pet .proposal-headline {
  background: url(bg/proposal_headline_mokuteki.png) no-repeat left bottom; }

.proposal-services {
  margin: 0;
  padding: 0; }
  .proposal-services li,
  .proposal-services .proposal-service {
    position: relative;
    list-style-type: none;
    background: url(bg/proposal.png) no-repeat left bottom; }
    .proposal-services li a,
    .proposal-services .proposal-service a {
      position: relative;
      display: block;
      width: 435px;
      height: 46px; }
    .proposal-services li .proposal-service-logo,
    .proposal-services .proposal-service .proposal-service-logo {
      display: none; }
    .proposal-services li .proposal-service-name,
    .proposal-services .proposal-service .proposal-service-name {
      display: block; }
    .proposal-services li.proposal-service-large,
    .proposal-services li:first-child,
    .proposal-services .proposal-service.proposal-service-large,
    .proposal-services .proposal-service:first-child {
      background: url(bg/proposal.png) no-repeat left top; }
      .proposal-services li.proposal-service-large a,
      .proposal-services li:first-child a,
      .proposal-services .proposal-service.proposal-service-large a,
      .proposal-services .proposal-service:first-child a {
        height: 92px; }
      .proposal-services li.proposal-service-large .proposal-service-logo,
      .proposal-services li:first-child .proposal-service-logo,
      .proposal-services .proposal-service.proposal-service-large .proposal-service-logo,
      .proposal-services .proposal-service:first-child .proposal-service-logo {
        display: block; }
      .proposal-services li.proposal-service-large .proposal-service-name,
      .proposal-services li:first-child .proposal-service-name,
      .proposal-services .proposal-service.proposal-service-large .proposal-service-name,
      .proposal-services .proposal-service:first-child .proposal-service-name {
        display: none; }
      .proposal-services li.proposal-service-large .proposal-service-pop-vail,
      .proposal-services li:first-child .proposal-service-pop-vail,
      .proposal-services .proposal-service.proposal-service-large .proposal-service-pop-vail,
      .proposal-services .proposal-service:first-child .proposal-service-pop-vail {
        background: url(bg/proposal_pop_vail_l.png) no-repeat; }

.proposal-service-copy {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  width: 193px;
  height: 14px; }

.proposal-service-logo, .proposal-service-name {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: auto;
  left: 228px; }

.proposal-service-logo {
  width: 166px;
  height: 91px; }

.proposal-service-name {
  width: 196px;
  height: 45px; }

.proposal-service-pop {
  display: none;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 168px;
  width: 289px;
  height: 115px;
  margin-top: -56px;
  background: url(bg/proposal_pop.png) no-repeat; }
  .proposal-service-pop img {
    position: absolute;
    margin: auto;
    top: 10px;
    left: 60px;
    width: 166px;
    height: 91px; }

.proposal-service-pop-icon {
  position: absolute !important;
  right: 12px;
  top: 10px;
  height: 16px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top right; }

.proposal-service-pop-vail {
  display: none;
  position: absolute;
  width: 289px;
  height: 115px;
  background: url(bg/proposal_pop_vail.png) no-repeat; }

.proposal-banners, .proposal-banners--row1, .proposal-banners--row3 {
  position: relative;
  height: 303px;
  margin: 20px 0 0 0;
  padding: 0; }
  .proposal-banners .psuedo-radius-2-lb,
  .proposal-banners--row1 .psuedo-radius-2-lb,
  .proposal-banners--row3 .psuedo-radius-2-lb,
  .proposal-banners .psuedo-radius-2-rb, .proposal-banners--row1 .psuedo-radius-2-rb, .proposal-banners--row3 .psuedo-radius-2-rb {
    bottom: 1px; }
  .proposal-banners li, .proposal-banners--row1 li, .proposal-banners--row3 li {
    list-style-type: none;
    float: left; }
  .proposal-banners .proposal-banner-l, .proposal-banners--row1 .proposal-banner-l, .proposal-banners--row3 .proposal-banner-l {
    width: 218px;
    height: 151px;
    overflow: hidden; }
  .proposal-banners .proposal-banner-r, .proposal-banners--row1 .proposal-banner-r, .proposal-banners--row3 .proposal-banner-r {
    width: 217px;
    height: 151px;
    overflow: hidden; }
  .proposal-banners .psuedo-shadow, .proposal-banners--row1 .psuedo-shadow, .proposal-banners--row3 .psuedo-shadow {
    content: ' ';
    width: 437px;
    height: 305px;
    right: -1px;
    top: -1px;
    background: url("bg/shadow_proposal_osusume.png") no-repeat top left;
    position: absolute;
    z-index: -1; }

.proposal-banners--row1 {
  height: 152px; }
  .proposal-banners--row1 .psuedo-shadow {
    content: ' ';
    width: 437px;
    height: 154px;
    right: -1px;
    top: -1px;
    background: url("bg/shadow_proposal_osusume-row1.png") no-repeat top left;
    position: absolute;
    z-index: -1; }

.proposal-banners--row3 {
  height: 435px; }
  .proposal-banners--row3 .psuedo-shadow {
    content: ' ';
    width: 437px;
    height: 456px;
    right: -1px;
    top: -1px;
    background: url("bg/shadow_proposal_osusume-row3.png") no-repeat top left;
    position: absolute;
    z-index: -1; }

.other-services {
  margin-bottom: 64px; }
  .other-services .psuedo-radius-wrapper {
    margin-top: 26px;
    border: 1px solid #e3e3e3; }

.other-services-list {
  position: relative;
  padding: 21px 25px 11px 21px;
  box-sizing: border-box; }
  .other-services-list li {
    display: block;
    font-size: 13px;
    margin-right: 19px;
    margin-bottom: 10px;
    letter-spacing: 1px; }
    .other-services-list li a {
      font-weight: bold;
      padding: 1px 0;
      display: inline-block; }
  .other-services-list [class^="psuedo-radius-border-"] {
    margin-left: 0; }

.other-services--ninshin .link-basic:before,
.other-services--nyuuji .link-basic:before,
.other-services--youji .link-basic:before,
.other-services-shougakusei .link-basic:before,
.other-services--chuugakusei .link-basic:before,
.other-services--koukousei .link-basic:before,
.other-services--shakaijin .link-basic:before {
  top: 1px;
  content: url(ic/blank.png); }

.other-services--mokuteki .link-basic:before {
  top: 1px;
  content: url(ic/blank.png); }

.slide-content {
  padding: 0 26px; }

.slide-visual {
  position: relative;
  min-width: 900px;
  overflow: hidden; }

.slide-visual-container {
  /** maybe override */
  width: 5120px;
  background-color: #f3f3f3; }
  .slide-visual-container img {
    width: auto;
    height: inherit;
    min-height: 100%;
    max-height: 100%;
    height: 100%\9;
    vertical-align: bottom;
    opacity: 0;
    -ms-filter: "alpha(opacity=0)";
    transform: scale(0.98);
    transition: transform 400ms ease-out; }
    .slide-visual-container img.show {
      transform: scale(1); }

.slide-visual-container--banner {
  width: 5120px;
  background-color: #f3f3f3; }
  .slide-visual-container--banner img {
    width: auto;
    height: inherit;
    min-height: 100%;
    max-height: 100%;
    height: 100%\9;
    vertical-align: bottom; }

.slide-visual-t {
  position: absolute;
  top: 0;
  right: 18px;
  left: 18px;
  height: 18px;
  background: url(bg/frame_slide_visual_ct.png) repeat-x; }
  .slide-visual-t:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -18px;
    width: 18px;
    background: url(bg/frame_slide_visual.png) no-repeat left top; }
  .slide-visual-t:after {
    content: '';
    position: absolute;
    top: 0;
    right: -18px;
    bottom: 0;
    width: 18px;
    background: url(bg/frame_slide_visual.png) no-repeat right top; }

.slide-visual-c {
  position: absolute;
  top: 18px;
  right: 0;
  bottom: 18px;
  left: 0;
  background: url(bg/frame_slide_visual_cc.png) repeat-y; }
  .slide-visual-c:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    background: url(bg/frame_slide_visual_cc.png) repeat-y; }

.slide-visual-b {
  position: absolute;
  right: 18px;
  bottom: 0;
  left: 18px;
  height: 18px; }
  .slide-visual-b:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -18px;
    width: 18px;
    background: url(bg/frame_slide_visual.png) no-repeat left bottom; }
  .slide-visual-b:after {
    content: '';
    position: absolute;
    top: 0;
    right: -18px;
    bottom: 0;
    width: 18px;
    background: url(bg/frame_slide_visual.png) no-repeat right bottom; }

/* TODO: 以下もう使ってないっぽい。ちゃんと精査して削除 */
.menu {
  position: relative; }

.menu-tab {
  display: none;
  height: 32px;
  text-align: right;
  padding-right: 5px;
  background: url(bg/menu-tab.png) no-repeat right 0; }

.menu-tab-item {
  display: block;
  float: right; }

.menu--top .menu-tab,
.menu--header .menu-tab {
  display: block;
  background: url(bg/menu-tab.png) no-repeat right 0; }

.menu-contents {
  position: relative;
  width: 900px;
  height: 83px;
  overflow: hidden;
  background: url(bg/menu_service_no_tab.png) no-repeat left bottom; }

.menu--top .menu-contents {
  background: url(bg/menu_service.png) no-repeat left bottom; }

.menu--service .menu-tab {
  background: url(bg/menu-tab.png) no-repeat right 0; }

.menu--service .menu-contents-service {
  display: block; }

.menu--service .menu-contents-member,
.menu--service .menu-contents-search {
  display: none; }

.menu--member .menu-tab {
  background: url(bg/menu-tab.png) no-repeat right -32px; }

.menu--member .menu-contents-member {
  display: block; }

.menu--member .menu-contents-service,
.menu--member .menu-contents-search {
  display: none; }

.menu--search .menu-tab {
  background: url(bg/menu-tab.png) no-repeat right -64px; }

.menu--search .menu-contents {
  background: url(bg/menu_search.png) no-repeat left bottom; }

.menu--search .menu-contents-search {
  display: block;
  padding: 12px; }

.menu--search .menu-contents-service,
.menu--search .menu-contents-member {
  display: none; }

.menu-shadow {
  position: absolute;
  z-index: -1;
  width: 902px;
  height: 83px;
  left: -1px;
  top: 2px;
  background: url(bg/shadow_menu.png) no-repeat left bottom; }

.menu--top .menu-shadow {
  top: 34px; }

.menu-content {
  position: relative;
  width: 900px;
  height: 83px;
  overflow: hidden;
  background: url(bg/menu_base.png) no-repeat left bottom; }
  .menu-content.menu-extra {
    height: 115px; }

.menu-top {
  height: 115px; }
  .menu-top .menu-content {
    background: url(bg/menu_base_tab_service.png) no-repeat left bottom;
    height: 115px; }
  .menu-top .menu-links-tabs {
    display: block;
    height: 32px;
    margin-left: 573px; }
  .menu-top .menu-shadow {
    top: 34px; }

.menu-links-tabs {
  margin: 0;
  display: none; }
  .menu-links-tabs li {
    display: block;
    margin: 0; }

.menu-links-above {
  margin: 0;
  margin-left: 172px;
  padding: 16px 0; }
  .menu-links-above li {
    display: block;
    margin: 0;
    margin-right: 18px; }
    .menu-links-above li a {
      vertical-align: bottom;
      display: inline-block; }

.menu-links-below {
  margin: 0;
  margin-left: 173px;
  padding-left: 0;
  padding-top: 11px;
  padding-bottom: 11px; }
  .menu-links-below li {
    display: block;
    margin: 0;
    margin-right: 18px; }
    .menu-links-below li a {
      vertical-align: baseline;
      display: inline-block; }

.menu-slide {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  width: 295px;
  height: 34px;
  background: url(bg/menu_slide.png) no-repeat left bottom; }
  .menu-slide:hover {
    background-position: left top; }
  .menu-slide.is-menu-slide-open {
    right: -231px;
    background: none; }
    .menu-slide.is-menu-slide-open .menu-slide-open {
      display: none; }
    .menu-slide.is-menu-slide-open .menu-slide-close {
      display: inline-block; }

.menu-slide-open {
  position: absolute;
  top: 0;
  left: 59px; }

.menu-slide-close {
  display: none;
  position: absolute;
  top: 0;
  left: 0; }

.menu-slide-link {
  position: absolute;
  top: 0;
  right: 3px; }

.menu-link-to-area {
  position: absolute;
  display: block;
  width: 130px;
  height: 26px;
  top: 4px;
  left: 180px; }

.menu-top {
  height: 115px; }
  .menu-top .menu-content {
    background: url(bg/menu_base_tab_service.png) no-repeat left bottom;
    height: 115px; }
  .menu-top .menu-links-tabs {
    display: block;
    height: 32px;
    margin-left: 573px; }
  .menu-top .menu-shadow {
    top: 34px; }

.menu-header {
  display: none;
  position: absolute;
  z-index: 1;
  height: 124px;
  top: -13px;
  right: -215px; }
  .menu-header .menu-content {
    background: url(bg/menu_base_header.png) no-repeat left bottom;
    height: 124px; }
  .menu-header .menu-links-tabs {
    display: block;
    padding: 0;
    margin-left: 549px;
    height: 41px; }
  .menu-header .menu-shadow {
    background: url(bg/shadow_menu_header.png) no-repeat left bottom;
    width: 932px;
    height: 156px;
    left: -16px;
    top: -9px; }
  .menu-header.menu-header--search {
    right: -13px; }
    .menu-header.menu-header--search .menu-content {
      background: url(bg/menu_search_header.png) no-repeat left bottom; }
    .menu-header.menu-header--search .menu-shadow {
      background: url(bg/shadow_menu_search_header.png) no-repeat left bottom; }
    .menu-header.menu-header--search .menu-search {
      padding: 13px; }
    .menu-header.menu-header--search .menu-links-tabs {
      margin-left: 837px; }

.menu-link-logo {
  text-decoration: none;
  position: absolute;
  top: 4px;
  z-index: 1;
  display: block;
  width: 143px;
  height: 25px; }

.menu-link-copy {
  text-decoration: none;
  position: absolute;
  top: 4px;
  left: 164px;
  z-index: 1;
  display: block;
  width: 96px;
  height: 25px; }

.menu-link-support {
  text-decoration: none;
  position: absolute;
  top: 4px;
  right: 88px;
  z-index: 1;
  display: block;
  width: 100px;
  height: 25px; }

.main-visual {
  position: relative;
  color: #fff;
  width: 900px;
  height: 500px;
  margin: 16px auto;
  margin-bottom: 38px; }
  .main-visual:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    right: -1px;
    left: -1px;
    height: 30px;
    background: url(bg/frame_main_visual_top.png) no-repeat left top; }
  .main-visual:after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 30px;
    right: -1px;
    bottom: -3px;
    left: -1px;
    background: url(bg/frame_main_visual_bottom.png) no-repeat left bottom; }

.main-visual-container {
  overflow: hidden;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0; }

.main-visual-purpose {
  position: relative;
  z-index: 1;
  margin-top: 39px;
  margin-bottom: 52px;
  padding-left: 39px;
  display: inline-block; }

.main-visual-subject {
  padding-left: 38px; }

.main-visual-benefit {
  position: absolute;
  z-index: 1;
  bottom: 35px;
  left: -15px; }

.main-visual-2 {
  position: relative;
  margin-bottom: 40px; }

.main-visual-2-container {
  z-index: -1;
  position: absolute;
  right: 0;
  width: 600px;
  height: 260px;
  margin-top: 1px; }
  .main-visual-2-container .main-visual-2-container-before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    right: -2px;
    left: -1px;
    width: 603px;
    height: 30px;
    background: url(bg/frame_main_2_visual_top.png) no-repeat left top; }
  .main-visual-2-container .main-visual-2-container-after {
    content: '';
    position: absolute;
    z-index: 0;
    top: 30px;
    right: -2px;
    bottom: -3px;
    left: -1px;
    background: url(bg/frame_main_2_visual_bottom.png) no-repeat left bottom; }

.main-visual-2-purpose {
  margin-top: -1px;
  margin-bottom: 51px; }

.main-visual-2-benefit {
  margin-bottom: 25px;
  margin-left: -1px; }

.entry {
  display: block;
  text-decoration: none; }
  .entry.entry-slim .entry-image {
    width: 225px;
    height: 97px;
    overflow: hidden; }
  .entry.entry-slim .entry-title {
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.78px; }

.entry-image {
  display: inline-block;
  position: relative;
  margin-bottom: 16px; }

.entry-image-icons {
  display: none;
  position: absolute;
  top: 8px;
  right: 8px; }
  .entry-image-icons [class^="ic-"] {
    margin-left: 2px; }

.entry-title {
  box-sizing: border-box;
  padding-right: 20px;
  display: block;
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.9px;
  overflow-wrap: break-word; }

.entry-description {
  box-sizing: border-box;
  padding-right: 20px;
  display: block;
  color: #666;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.78px;
  overflow-wrap: break-word;
  text-decoration: none; }

.entry-2 {
  display: block; }

.entry-2-image {
  margin-top: 2px;
  position: relative;
  float: left; }

.entry-2-title {
  margin-top: -2px;
  box-sizing: border-box;
  color: #000;
  margin-left: 124px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.78px; }

.entry-2-description {
  box-sizing: border-box;
  color: #666;
  margin-left: 124px;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.6px;
  text-decoration: none; }

a:hover .entry-title,
a:hover .entry-2-title {
  text-decoration: underline;
  color: #666; }

.contact-to {
  width: 900px;
  height: 91px;
  background: url(bg/footer_contact_to.png) no-repeat left top; }
  .contact-to.is-footer-phone-expanded {
    background: url(bg/footer_contact_to.png) no-repeat left bottom; }
    .contact-to.is-footer-phone-expanded .contact-to-way--toggle .contact-to-way-label2 {
      background: url(/common/images/footer/txt_by_phone_do_open.png) no-repeat left top !important; }
    .contact-to.is-footer-phone-expanded .contact-to-way--toggle:hover .contact-to-way-label2 {
      background: url(/common/images/footer/txt_by_phone_do_open.png) no-repeat left bottom !important; }

.contact-to-way {
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  padding: 26px 0 24px 25px; }
  .contact-to-way:hover {
    background: url(bg/footer_contact_to_c.png) no-repeat left top; }
    .contact-to-way:hover .contact-to-way-label,
    .contact-to-way:hover .contact-to-way-label2 {
      background-position: left bottom; }
  .contact-to-way.contact-to-way--left:hover {
    background: url(bg/footer_contact_to_l.png) no-repeat left top; }
  .contact-to-way.contact-to-way--right:hover {
    background: url(bg/footer_contact_to_r.png) no-repeat left top; }

.contact-to-way-label {
  display: block;
  background-position: left top;
  background-repeat: no-repeat;
  width: 200px;
  height: 13px;
  margin-bottom: 13px; }

.contact-to-way-label2 {
  display: block;
  background-position: left top;
  background-repeat: no-repeat;
  width: 200px;
  height: 15px; }

.contant-by-phone {
  display: none;
  height: 98px;
  background: url(bg/footer_contact_by_phone.png) no-repeat left top; }

.contant-by-phone-number {
  padding: 32px 0 0 26px; }

.contant-by-phone-description {
  padding: 22px 32px 0 0;
  color: #999;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.6px; }

.relative-feature {
  margin-bottom: 45px; }

.relative-feature-link-to-all {
  display: block;
  float: right;
  margin-top: 6px;
  font-size: 13px;
  font-weight: 400; }

.relative-feature-entries {
  position: relative; }
  .relative-feature-entries .psuedo-shadow {
    content: ' ';
    width: 902px;
    height: 197px;
    right: -1px;
    top: 0;
    background: url("bg/shadow_relative_feature.png") no-repeat top left;
    position: absolute;
    z-index: -1; }
  .relative-feature-entries .entry {
    width: 450px; }

.social-media img {
  vertical-align: middle; }

.service-by-seichou-list {
  position: relative;
  margin-bottom: 20px; }
  .service-by-seichou-list a {
    display: block; }
    .service-by-seichou-list a img {
      display: block; }
  .service-by-seichou-list .psuedo-shadow {
    content: ' ';
    width: 902px;
    height: 158px;
    right: -1px;
    top: 0;
    background: url("bg/shadow_service_seicho.png") no-repeat top left;
    position: absolute;
    z-index: -1; }

.service-by-seichou-img {
  margin-bottom: 13px; }

.service-by-mokuteki {
  background: url(bg/service_mokuteki_frame.png) no-repeat top left;
  width: 900px;
  height: 232px;
  margin-bottom: 52px; }

.service-by-mokuteki-list {
  margin-bottom: 53px; }
  .service-by-mokuteki-list a {
    display: block;
    box-sizing: border-box;
    width: 225px;
    height: 77px; }
  .service-by-mokuteki-list .service-by-mokuteki-label {
    display: inline; }
  .service-by-mokuteki-list .service-by-mokuteki-label--hover {
    display: none; }
  .service-by-mokuteki-list li {
    /** Nth child for IE8 */ }
    .service-by-mokuteki-list li:hover a {
      background: url(bg/service_mokuteki.png) no-repeat top left; }
      .service-by-mokuteki-list li:hover a .service-by-mokuteki-label {
        display: none; }
      .service-by-mokuteki-list li:hover a .service-by-mokuteki-label--hover {
        display: inline; }
    .service-by-mokuteki-list li:first-child:hover a {
      background: url(bg/service_mokuteki_1st.png) no-repeat top left; }
    .service-by-mokuteki-list li:first-child + li + li + li:hover a {
      background: url(bg/service_mokuteki_4th.png) no-repeat top left; }
    .service-by-mokuteki-list li:first-child + li + li + li + li + li + li + li:hover a {
      background: url(bg/service_mokuteki_8th.png) no-repeat top left; }
    .service-by-mokuteki-list li:first-child + li + li + li + li + li + li + li + li:hover a {
      background: url(bg/service_mokuteki_9th.png) no-repeat top left; }
    .service-by-mokuteki-list li img {
      display: block;
      padding: 29px 0 28px 20px; }

.service-col-2 .service-col-2-right {
  box-sizing: border-box;
  padding-left: 15px; }

.service-col-2 .headline-with-icon {
  margin-bottom: 17px; }

.service-col-2 .link-list li {
  font-size: 16px;
  letter-spacing: 0.96px;
  line-height: 34px;
  margin-bottom: 0; }

.member-info, .member-available-procedure {
  border: 1px solid #e3e3e3;
  padding: 26px 25px 18px; }

.member-info {
  position: relative;
  margin-bottom: 52px; }

.member-info-headline {
  margin-bottom: 14px; }

.member-info-list {
  font-size: 13px;
  letter-spacing: 1.17px;
  line-height: 26px; }

.member-register-procedure {
  margin-bottom: 52px; }

.member-login {
  margin-bottom: 27px; }

.member-login-start {
  display: block;
  margin-bottom: 6px; }

.member-login-remember {
  margin-top: 12px;
  display: block; }

.member-subscription-procedure {
  margin-bottom: 14px; }

.member-available-procedure {
  position: relative; }

.member-available-procedure-headline {
  margin-bottom: 14px; }

.member-available-procedure-lists {
  margin-bottom: 12px;
  margin-left: -4px; }
  .member-available-procedure-lists ul {
    float: left;
    width: 284px; }
    .member-available-procedure-lists ul li {
      font-size: 13px;
      letter-spacing: 0.78px;
      line-height: 26px; }
      .member-available-procedure-lists ul li:before {
        content: "・";
        margin-right: 2px; }

.subscription-procedures {
  background: url(bg/member_subscription_frame.png) no-repeat top left; }
  .subscription-procedures li {
    float: left; }
    .subscription-procedures li a {
      display: block;
      box-sizing: border-box;
      width: 224px;
      height: 143px;
      padding: 22px 26px; }

.subscription-procedure-title {
  display: block;
  font-size: 13px;
  letter-spacing: 0.78px;
  line-height: 20px;
  margin-bottom: 10px;
  padding-left: 19px;
  background: url(ic/blank.png) no-repeat 0 6px; }

.subscription-procedure-description {
  display: block;
  color: #666;
  font-size: 10px;
  letter-spacing: 0.6px;
  line-height: 20px; }

.member-rule {
  margin-bottom: 47px;
  font-size: 13px;
  letter-spacing: 0.78px;
  line-height: 20px; }

.member-faq {
  margin-bottom: 117px; }

.member-faq-search {
  position: relative;
  border: 1px solid #e3e3e3;
  padding: 25px 23px 23px; }

.member-faq-search-desctiption {
  font-size: 13px;
  letter-spacing: 1.17px;
  line-height: 26px; }

.member-faq-search-input {
  font-size: 13px;
  display: inline-block;
  box-sizing: border-box;
  width: 411px;
  padding: 12px 14px;
  background: url(bg/member_search_input.png) no-repeat;
  border: none; }

.member-faq-search-submit {
  cursor: pointer;
  display: inline-block;
  width: 70px;
  height: 40px;
  background: url(bg/member_search_submit.png) no-repeat;
  border: none;
  text-indent: 120%;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 10px; }

.member-faq-list-contents {
  position: relative;
  border: 1px solid #e3e3e3;
  border-top: none;
  padding: 25px 24px 18px; }

.member-faq-list, .member-faq-list--by-service {
  font-size: 13px;
  letter-spacing: 0.78px;
  line-height: 26px; }
  .member-faq-list [class="link-blank"], .member-faq-list--by-service [class="link-blank"] {
    display: inline-block;
    position: relative;
    padding-left: 20px; }
    .member-faq-list [class="link-blank"]:before, .member-faq-list--by-service [class="link-blank"]:before {
      position: absolute;
      top: 7px;
      left: 0; }

.member-faq-list--by-service li {
  float: left;
  box-sizing: border-box;
  width: 420px; }
  .member-faq-list--by-service li.u-right {
    float: right;
    padding-left: 9px; }

.member-faq-list--by-service [class="link-basic"] {
  display: inline-block;
  position: relative;
  padding-left: 20px; }
  .member-faq-list--by-service [class="link-basic"]:before {
    position: absolute;
    top: 4px;
    left: 0; }

.announcement {
  position: relative;
  background-color: #f2f2f2;
  padding: 13px;
  margin-top: 24px; }
  .announcement .announcement-list {
    font-size: 13px;
    letter-spacing: 1.17px;
    line-height: 23px; }

.is-announcement-hidden {
  display: none; }

.notfound-description {
  font-size: 13px;
  line-height: 26px;
  color: #666; }

.info-entry {
  margin-bottom: 118px; }

.info-entry-title {
  letter-spacing: 1.32px;
  font-weight: normal;
  font-size: 22px;
  padding-bottom: 18px;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 51px; }

.info-entry-date {
  letter-spacing: 0.9px;
  font-size: 15px;
  margin-bottom: 42px; }

.info-entry-body {
  width: 620px;
  letter-spacing: 0.9px;
  line-height: 30px; }
  .info-entry-body p {
    font-size: 15px;
    margin: 0 auto 22px; }
  .info-entry-body h4 {
    font-size: 15px;
    margin: 36px auto 8px; }

.info-entry-backto {
  margin-top: 53px;
  font-size: 13px; }

.searchbox {
  position: relative; }

.searchbox-input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 57px;
  font-size: 33px;
  padding: 8px 80px 8px 54px;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: #f2f2f2;
  background-image: url(ic/search.png);
  background-position: 20px 16px;
  background-repeat: no-repeat; }

.searchbox-submit {
  cursor: pointer;
  position: absolute;
  background: url(ic/search-submit.png) no-repeat left top;
  display: block;
  width: 132px;
  height: 57px;
  top: 0;
  right: 0;
  border: none; }

.m-area {
  box-sizing: border-box;
  position: relative;
  border: 1px dashed rgba(0, 0, 0, 0.125);
  background-color: rgba(0, 0, 0, 0.125); }
  .m-area.red {
    border: 1px dashed rgba(255, 0, 0, 0.125);
    background-color: rgba(255, 0, 0, 0.125); }
  .m-area.green {
    border: 1px dashed rgba(0, 128, 0, 0.125);
    background-color: rgba(0, 128, 0, 0.125); }
  .m-area.blue {
    border: 1px dashed rgba(0, 0, 255, 0.125);
    background-color: rgba(0, 0, 255, 0.125); }
  .m-area.yellow {
    border: 1px dashed rgba(255, 255, 0, 0.125);
    background-color: rgba(255, 255, 0, 0.125); }
  .m-area.pink {
    border: 1px dashed rgba(255, 192, 203, 0.125);
    background-color: rgba(255, 192, 203, 0.125); }
  .m-area.trans {
    border: 1px dashed transparent;
    background-color: transparent; }

.m-hidden {
  display: none; }

.m-abs {
  position: absolute; }

.m-center {
  margin-left: auto;
  margin-right: auto; }
